
  import { Component, Mixins, Watch, Prop } from "vue-property-decorator";
  import { Getter, Action } from "vuex-class";
  import { formatDate } from "@/utils/formatters";
  const groupBy = require("lodash/groupBy");
  import { HasFormErrors } from "@/mixins/has-form-errors";

  import type { indexTimelinePayload } from "@/store/modules/timeline.store";

  @Component({
    components: {
      Spinner: () => import("@/components/general/spinner.vue"),
      TimelineEntryCard: () => import("@/components/timeline/entry-card.vue"),
      TimelineCreateEntry: () => import("@/components/timeline/create-entry.vue"),
      EditTimelineEntryModal: () => import("@/components/modals/edit-timeline-entry.vue"),
    },
  })
  export default class DossierTabTimeline extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("timeline/all") timelineItems!: TimelineItem[];
    @Getter("timeline/notes") timelineNotes!: TimelineItem[];
    @Action("timeline/index") indexTimeline!: (payload: indexTimelinePayload) => Promise<TimelineItem[]>;
    @Action("timeline/notes") indexNotes!: (payload: indexTimelinePayload) => Promise<TimelineItem[]>;

    @Prop({ required: false, default: "all" }) type!: "notes" | "all";

    loading = false;

    get entries() {
      if (this.type === "all") {
        return this.timelineItems;
      }

      return this.timelineNotes;
    }

    get timelineItemsByDate(): { [key: string]: TimelineItem[] } {
      if (!this.entries) {
        return {};
      }

      const day = (entry: TimelineItem) => formatDate(entry.updated_at, "dddd D MMMM");

      return groupBy(this.entries, day);
    }

    async mounted() {
      this.loading = true;

      await this.queryTimeline();

      this.loading = false;
    }

    async queryTimeline() {
      return await this.indexTimeline({ q: { and: { activity_id: this.activity.id } }, per_page: 900, s: { created_at: "desc" } });
    }

    @Watch("$store.state.timeline.checkUpdate")
    handleTimelineChanged() {
      // Fetch a timeline feed update, but only if this component is rendered
      // and there is an update flag raised.
      this.queryTimeline();
    }
  }
